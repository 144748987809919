/*================================================================================
NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./components/tools/index.scss";

html body p {
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

body {
    font-family: "Rubik" !important;
    color: #6e6b7b !important;
}

body a {
    font-family: "Nunito", sans-serif !important;
}

img {
    border-style: none;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.container-scss {
    display: flex;
    min-height: 100vh;

    .container-body {
        padding: 24px;
        background-color: #e3e3e3;
        min-height: 100vh;
    }
    .container-body-wizard {
        padding: 24px;
        background-color: #F2F3F5;
        min-height: 100vh;
    }

    p {
        //display: block;
        //margin-block-start: 1em;
        //margin-block-end: 1em;
        //margin-inline-start: 0px;
        //margin-inline-end: 0px;
    }
}

.sidebar-profile {
    background-color: #fafafa;
    padding: 12px 1px;
    min-height: 8rem;

    .sidebar-body {
        padding: 15px 10px 10px 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .user-info {
        text-align: center;
        height: auto;
        padding: 10px 0 0 0;

        h6 {
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0px;
            margin-top: 0;
            margin-bottom: 0;
            opacity: 1;
            line-height: 1.2;
        }

        div.bg-info {
            background: var(--bg-info-overlay) 0% 0% no-repeat padding-box;
            border-radius: 13px;
            color: var(--bg-info-main);
            font-size: 10px;
            letter-spacing: 0px;
            width: 140px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        p {
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 0;
            opacity: 1;
            color: #888ea8 !important;
            margin-top: 0;
            margin-bottom: 0;
        }

        p:last-child {
            margin-bottom: 0.25rem;
        }
    }
}

.ant-menu {
    .ant-menu-item:first-child {
        height: auto;
    }
}

.ant-form-item {
    margin-bottom: 8px !important;

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding: 0 !important;
    }
}

.d-flex {
    display: flex !important;
}

.justify-center {
    justify-content: center;
}

.icon-action {
    cursor: pointer;
}

.align-self-center {
    align-self: center !important;
}

.flex-row {
    flex-direction: row !important;
}

.mr-1 {
    margin-right: 0.8rem !important;
}

.ant-dropdown-menu {
    border-radius: 12px !important;
    padding: 0.5rem 0 !important;
    font-size: 1rem !important;
    .ant-dropdown-menu-item {
        padding: 0.65rem 1.28rem;
    }
}

.container-spinner {
    // position: absolute;
    // height: 100vh;
    // width: 100%;
    // text-align: center;
    // background: rgba(255, 255, 255, 0.65);
    // z-index: 9;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding: 50px;
    position: fixed;
    top: 0;
    left: 0;
    background: #f3f3f377;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    // .ant-spin {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    // }
}

.ant-select.readOnly {
    pointer-events: none;
}

.content {
    //height: 100vh;
    background-color: #f8f8f8;

    div:first-child > .ant-row {
        //height: 100vh;
        margin: auto;
    }

    div:first-child > div.ant-col:nth-child(2) {
        //height: 100vh;
        //background-color: #ffffff;
    }

    img.img-logo {
        position: absolute !important;
        top: 2rem;
        left: 2rem;
        margin: 0;
        z-index: 1;
        width: 13%;
    }
}

.ant-space {
    width: 100%;

    .ant-space-item {
        display: flex;
        // width: 100%;
    }
}

.bg-auth {
    padding: 3rem !important;
    background: #fff;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;


    p.desc-center {
        text-align: center;
        margin-block-start: 0;
    }

    .btn-google {
        border-radius: 7px !important;
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
    }
}

.font-weight-bold {
    font-weight: 500 !important;
}

.font-weight-bolder {
    font-weight: 600 !important;
}

.font-small-2 {
    font-size: 0.8rem !important;
}

.text-muted {
    color: #b9b9c3 !important;
}

.text-center {
    text-align: center;
}

.ant-image {
    .img-fluid {
        padding: 12px;
    }
}

.img-soal {
    width: 100px;
    object-fit: contain;
    height: 80px;
}

.img-fluid,
.img-thumbnail {
    max-width: 100%;
    height: auto;
}

.wrapper-bg {
    position: relative;

    .img-conference {
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .bg-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background: rgba(51, 51, 51, 0.49);
        border-radius: 6px;
    }

    .info-conference {
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 3;
        position: absolute;
        bottom: 0;
        left: 8px;

        h4 {
            color: #fff;
            margin-block-start: 0;
            margin-block-end: 0.3em;
        }
    }

    .action {
        justify-content: center;
        align-items: center;
        display: flex;
        z-index: 3;
        position: absolute;
        bottom: 0;
        right: 2px;

        .icon {
            display: inline-block;
            right: 8px;
            position: relative;
            cursor: pointer;
        }

        .icon:nth-child(1) {
            margin-right: 10px;
        }

        .icon:nth-child(2) {
            margin-right: 8px;
        }
    }
}

.bg-illustration-login {
    //background-color: var(--primary-color-light);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-carousel .slick-dots-bottom {
    position: relative !important;
    padding-top: 20px !important;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
        color: var(--btn-primary);
    }
}

.logo {
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left: 40px;
}

.table {
    width: 100%;

    thead {
        tr {
            th {
                text-align: -webkit-match-parent;
                vertical-align: top;
                text-transform: uppercase;
                font-size: 0.857rem;
                letter-spacing: 0.5px;
                border-bottom: 2px solid #ebe9f1;
                border-top: 1px solid #ebe9f1;
                padding: 0.72rem 2rem;
            }
        }
    }

    .table-warning {
        background-color: #ffe4ca;

        &:hover {
            background-color: #ffe4ca;
        }
    }
}

.site-layout .site-layout-background {
    background: var(--top-nav);
    height: auto;
}

.btn {
    display: inline-block;
    color: #6e6b7b;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.786rem 1.5rem;
    font-size: 1rem;
    line-height: 1;
    border-radius: 0.358rem;
    cursor: pointer;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, background 0s, border 0s;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        background 0s, border 0s;

    &.btn-block {
        display: block;
        width: 100%;
    }

    &.btn-default {
        border: 1px solid var(--btn-primary) !important;
        background-color: transparent;
        color: var(--btn-primary);

        &:focus {
            outline: none;
            background-color: rgba(118, 183, 52, 0.2);
            color: var(--btn-primary);
        }

        &:hover {
            outline: none;
            background-color: rgba(118, 183, 52, 0.04);
            color: var(--btn-primary);
        }

        &:disabled {
            opacity: 0.65;
            cursor: not-allowed;
        }
    }

    &.btn-primary {
        color: white;
        border-color: var(--btn-primary) !important;
        background-color: var(--btn-primary) !important;

        &:hover {
            box-shadow: 0 8px 25px -8px var(--btn-primary);
        }

        &:focus {
            outline: none;
            color: #fff;
            background-color: var(--secondary-color) !important;
        }

        &:disabled {
            opacity: 0.65;
            cursor: not-allowed;
        }
    }

    &.btn-warning {
        color: white;
        border-color: #ff9f43 !important;
        background-color: #ff9f43 !important;

        &:hover {
            box-shadow: 0 8px 25px -8px #ff9f43;
        }

        &:focus {
            outline: none;
            color: #fff;
            background-color: #ff922a !important;
        }
    }

    &.btn-danger {
        color: white;
        border-color: #ea5455 !important;
        background-color: #ea5455 !important;

        &:hover {
            box-shadow: 0 8px 25px -8px #ea5455;
        }

        &:focus {
            outline: none;
            color: #fff;
            background-color: #e73d3e !important;
        }
    }

    &.btn-sm {
        padding: 0.486rem 1rem;
        font-size: 0.9rem;
        line-height: 1;
        border-radius: 0.358rem;
    }
}

.my-2 {
    margin-bottom: 1.5rem !important;
    margin-top: 1.5rem !important;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;

    &.card-func {
        padding: 0.8rem;
        margin-left: 12px;
        align-items: flex-end !important;

        .grid-icon {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.8em 1.2em 0.8em 1.2em;
            border-radius: 8px;
            border: 1px dashed #6e6b7b;
            margin-left: 12px;

            &:hover {
                //option on hover, but i still don't have any ideas right now wkwkkw
            }

            span {
                margin-top: 6px;
            }
        }
    }

    .card-body {
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.5rem;

        .card-title {
            font-weight: 500;
            font-size: 1.285rem;
            margin-bottom: 1.53rem;
        }

        .card-link + .card-link {
            margin-left: 1.5rem;
        }
    }
}

h4.info-error {
    font-weight: 500;
    font-size: 1.285rem;
    margin-bottom: 1.53rem;
}

.list-class {
    border-radius: 5px !important;
    //min-height: 20rem;
}

.list-materi {
    border-radius: 8px !important;
    min-height: 10rem;
}

.list-student {
    border-radius: 8px !important;
    min-height: 10rem;
}

.max-one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.d-hide {
    display: none !important;
}

.max-two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

@media (max-width: 575.98px) {
    .container-scss {
        display: flex;
        height: auto;
    }

    .card-body {
        padding: 3.5rem;
    }
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: var(--primary-color) !important;
}

.ant-carousel .slick-dots li button {
    background: orange !important;
    width: 7px !important;
    height: 7px !important;
    border-radius: 10rem !important;
}
.description_ujian {
    img {
        max-width: 300px;
    }
}
