.calculator-body {
    margin: 8px 0;
    .result-box {
        margin: 4px 0;
        border: 1px solid gray;
        border-radius: 4px;
        height: 32px;
        width: 100%;
        max-width: 135px;
        text-align: right;
        padding: 4px;

        p {
            margin: 0;
        }
    }

    .button {
        button {
            width: 32px;
            height: 32px;
            margin: 1px;
        }
    }
}
