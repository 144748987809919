.button-atom-general {
  background-color: #8fbe4d;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;

  &-text {
    margin: 0px;
    font-weight: normal;
    font-size: 16px;
    padding: 10px;
    color: #ffff;
  }
}

.button-atom-general:hover {
  background-color: #8fbe4d;
  border-color: #40a9ff;
}

.button-atom-general:focus {
  background-color: #8fbe4d;
  border-color: #40a9ff;
}
