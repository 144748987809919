$padding: 8px 15px;

::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.main-menu {
    overflow: hidden;
    font-weight: 500 !important;
    min-height: calc(100vh - 86px);
    background: var(--primary-color);
    margin-bottom: 25px;
    &-box {
        width: 100%;
        padding-right:10px;
    }
    &-info {
        background: #F6F6F6;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        &-role {
            span {
                font-weight: 500;
                line-height: normal;
            }
        }

        &-school {
            background: var(--bg-info-main);
            color: #fff;
            padding: 4px 10px;
            border-radius: 8px;
            span {
                width: 100%;
                display: block;
                line-height: normal;
                font-size: 10px;
            }
        }
    }

    &-content {
        border-top-right-radius: 75px;
        background: var(--primary-color);
        width: 100%;
        padding: 20px 0 0 10px;
        @media (max-width: 767px) {
            width: 100%;
        }


        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            font-size: 14px;
            color: #fff;
            transition: all 0.3s ease;

            a {
                color: #fff;
                display: block;
                padding: $padding;
            }

            &:hover {
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                background: #fff;

                a {
                    color: var(--primary-color);
                }
            }
        }

        h6 {
            font-size: 14px;
            font-weight: bold;
            color: #fff;
            padding: $padding;
        }
    }
}

.dropdown-primary.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
    background-color: var(--primary-color);
    color:whitesmoke;
}
.ant-menu-root {
    background-color: var(--primary-color)  !important;
    color: #FFFFFF;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: var(--primary-color) !important;
    color: #FFFFFF !important;
    border: 2px solid var(--secondary-color);
    box-sizing: border-box;
    border-radius: 8px;
}
.dropdown-primary-active.ant-select-dropdown {
    border-radius: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.dropdown-primary .ant-select-arrow {
    color: white;
}
