.wrapper-item-class-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 40px;
    margin-right: 121px;

    .title-item-class-media {
        margin: 0px;
        font-size: 18px;
        font-weight: normal;
        color: #303840;
        margin-left: 20px;
    }

    @media (max-width: 768px) {
        margin-right: 80px;
        
    }
    @media (max-width: 425px) {
        margin-right: 80px;
        .title-item-class-media{
            font-size: 12px;
        }
    }
}
